<template lang="pug">
.player
  season-segment-selector(
    :leagueId="leagueId",
    @leaf-node-clicked="leafNodeClicked"
  )
  v-container(
    v-for="(leaderObject, index) in leaderData",
    :key="leaderObject.title"
  )
    v-row.mt-5
      v-col(cols="12")
        h5.normal_title_h5 {{ leaderObject.title }}
        v-divider.d-block
    v-row.mt-5
      players-ranking-card-slider(
        :playerLeaders="leaderObject.offensive",
        :leaderTitles="leaderTitles[0]",
        @playerClick="playerClick"
      )
      players-ranking-card-slider(
        :playerLeaders="leaderObject.defensive",
        :leaderTitles="leaderTitles[1]",
        @playerClick="playerClick"
      )
  v-divider(style="margin-top: 60px; border-width: 2px")
  v-container.mb-10
    v-row.mt-16
      v-col(cols="12")
        h5.normal_title_h5 全部球員
    v-row.mt-5(justify="end")
      v-col(cols="6", md="3")
        select(v-model="selectedTeam", @change="filterChange")
          option(value="all") 全部
          option(v-for="team in teamOptions", :value="team.name") {{ team.name }}
      v-col(cols="6", md="3")
        input.search-input(
          type="text",
          placeholder="搜尋球員名稱",
          v-model="searchPlayerName",
          @input="filterChange"
        )
    v-row.table_row
      v-col#sticky-nav.table_col.table.position-relative.mt-4(cols="12")
        v-overlay(v-model="playerTableOverlayVisible", absolute, opacity="0.1")
          v-progress-circular(indeterminate, color="primary")
        table(style="height: 500px")
          thead
            tr.top_head
              sticky-column.bdr2(colspan="1", :offset="0")
              th(colspan="3")
              th(colspan="5")
              th.bdr(colspan="3") 投籃
              th.bdr(colspan="3") 兩分
              th.bdr(colspan="3") 三分
              th.bdr(colspan="3") 罰球
              th.bdr(colspan="2") 籃板
              th(colspan="4")
            tr.bottom_head
              sticky-column.bdr2(colspan="1", title="球員", :offset="0")
              th
                span 隊伍
              th(
                v-for="header in boxTableHeaders",
                :key="header.key",
                style="cursor: pointer",
                @click="sortClick(header.key)",
                :class="getHeaderClassObject(header)"
              )
                span {{ header.name }}
                font-awesome-icon.ml-2(:icon="getTableSortIcon(header.key)")
          tbody
            tr.hover.clickable(
              v-for="player in playerPage",
              :key="player.player_id",
              @click="playerClick(player)"
            )
              sticky-column.bdl.bdr2(
                style="width: 120px; min-width: 120px",
                :offset="0",
                :title="player.name"
              )
              td {{ player.team_name }}
              td(
                v-for="header in boxTableHeaders",
                :key="header.key",
                :class="getDataClassObject(header, player.overall[header.key])"
              ) {{ player.overall[header.key] }}
    v-row
      v-col
        v-pagination(
          v-model="currentPage",
          :length="totalPages",
          prev-icon="mdi-menu-left",
          next-icon="mdi-menu-right",
          circle
        )
</template>

<script>
import PlayersRankingCardSlider from "@/components/league/PlayersRankingCardSlider";
import StickyColumn from "@/components/common/Table/StickyColumn";
import SeasonSegmentSelector from "@/components/league/SeasonSegmentSelector";
import { getPlayerOverview } from "@/api/league";

export default {
  name: "LeaguePlayerOverview",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    PlayersRankingCardSlider,
    StickyColumn,
    SeasonSegmentSelector,
  },
  data() {
    return {
      leagueId: Number(this.$route.params.leagueId),
      seasonId: 0,
      seasonSegmentId: 0,

      leaderTitles: [
        {
          title: "進攻排行",
          titleEng: "Offensive Leaders",
        },
        {
          title: "防守排行",
          titleEng: "Defensive Leaders",
        },
      ],
      leaderData: [],

      teamOptions: [],
      selectedTeam: "all",
      searchPlayerName: "",
      sortKey: "",
      sortOrder: "",

      players: [],
      filteredPlayers: [],
      boxTableHeaders: [
        {
          name: "出賽",
          key: "gp",
          border: false,
          bold: false,
        },
        {
          name: "時間",
          key: "min",
          border: true,
          bold: false,
        },
        {
          name: "得分",
          key: "pts",
          border: false,
          bold: true,
        },
        {
          name: "籃板",
          key: "reb",
          border: false,
          bold: true,
        },
        {
          name: "助攻",
          key: "ast",
          border: false,
          bold: true,
        },
        {
          name: "抄截",
          key: "stl",
          border: false,
          bold: true,
        },
        {
          name: "阻攻",
          key: "blk",
          border: true,
          bold: true,
        },
        {
          name: "命中",
          key: "fgm",
          border: false,
          bold: false,
        },
        {
          name: "出手",
          key: "fga",
          border: false,
          bold: false,
        },
        {
          name: "命中率",
          key: "fg_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg2m",
          border: false,
          bold: false,
        },
        {
          name: "出手",
          key: "fg2a",
          border: false,
          bold: false,
        },
        {
          name: "命中率",
          key: "fg2_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "fg3m",
          border: false,
          bold: false,
        },
        {
          name: "出手",
          key: "fg3a",
          border: false,
          bold: false,
        },
        {
          name: "命中率",
          key: "fg3_pct",
          border: true,
          bold: false,
        },
        {
          name: "命中",
          key: "ftm",
          border: false,
          bold: false,
        },
        {
          name: "出手",
          key: "fta",
          border: false,
          bold: false,
        },
        {
          name: "命中率",
          key: "ft_pct",
          border: true,
          bold: false,
        },
        {
          name: "進攻",
          key: "oreb",
          border: false,
          bold: false,
        },
        {
          name: "防守",
          key: "dreb",
          border: true,
          bold: false,
        },
        {
          name: "失誤",
          key: "tov",
          border: false,
          bold: false,
        },
        {
          name: "犯規",
          key: "pf",
          border: false,
          bold: false,
        },
        {
          name: "正負值",
          key: "plus_minus",
          border: false,
          bold: false,
        },
        {
          name: "效率值",
          key: "eff",
          border: true,
          bold: false,
        },
      ],

      currentPage: 1,
      totalPages: 1,
      pageSize: 12,

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  computed: {
    playerTableOverlayVisible() {
      return this.players.length === 0;
    },
    playerPage() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.filteredPlayers.slice(start, end);
    },
  },
  watch: {
    seasonSegmentId() {
      this.currentPage = 1;
      this.totalPages = 1;
      this.resetFilter();
      this.getPlayerOverview();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {},
    leafNodeClicked(node) {
      this.seasonSegmentId = node.id;
      this.seasonId = node.season_id;
    },
    async getPlayerOverview() {
      const params = { season_segment_id: this.seasonSegmentId };
      const response = await getPlayerOverview(params);
      console.log("response", response);
      this.leaderData = response.data.leader_data;
      this.teamOptions = response.data.team_options;
      this.players = response.data.players;
      this.filteredPlayers = Array.from(this.players);
      this.totalPages = Math.ceil(this.filteredPlayers.length / this.pageSize);
    },
    filterChange() {
      this.filteredPlayers = Array.from(this.players);
      this.filteredPlayers = this.filteredPlayers.filter((player) =>
        player.name.includes(this.searchPlayerName)
      );

      if (this.selectedTeam !== "all")
        this.filteredPlayers = this.filteredPlayers.filter(
          (player) => player.team_name === this.selectedTeam
        );
      this.totalPages = Math.ceil(this.filteredPlayers.length / this.pageSize);
    },
    resetFilter() {
      this.filteredPlayers = Array.from(this.players);
      this.selectedTeam = "all";
      this.searchPlayerName = "";
      this.totalPages = Math.ceil(this.filteredPlayers.length / this.pageSize);
      this.sortKey = "";
      this.sortOrder = "";
    },
    getHeaderClassObject(header) {
      return {
        sort_up: this.sortKey == header.key && this.sortOrder == "ASC",
        sort_down: this.sortKey == header.key && this.sortOrder == "DESC",
        bdr: header.border,
      };
    },
    getDataClassObject(header, value) {
      return {
        bdr: header.border,
        blue_heavy: value > 0 && header.bold,
        gray_out_heavy: value <= 0,
      };
    },
    getTableSortIcon(key) {
      if (key == this.sortKey) {
        if ("ASC" == this.sortOrder) {
          return ["fas", "caret-up"];
        } else {
          return ["fas", "caret-down"];
        }
      } else {
        return ["fas", "sort"];
      }
    },
    sortClick(key) {
      if (key !== this.sortKey) {
        this.sortKey = key;
        this.sortOrder = "DESC";
        this.sortPlayers();
      } else {
        if (this.sortOrder == "DESC") {
          this.sortOrder = "ASC";
          this.sortPlayers();
        } else {
          this.sortKey = "";
          this.sortOrder = "";
          this.filterChange();
        }
      }
    },
    sortPlayers() {
      this.filteredPlayers.sort((a, b) => {
        if (this.sortOrder === "DESC")
          return b.overall[this.sortKey] - a.overall[this.sortKey];
        else return a.overall[this.sortKey] - b.overall[this.sortKey];
      });
    },
    playerClick(player) {
      this.$router.push({
        name: "LeaguePlayer",
        params: {
          leagueId: this.leagueId,
          seasonSegmentId: this.seasonSegmentId,
          playerId: player.player_id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/common/table";
@import "@/assets/style/common/pagination";

table {
  text-align: center;
}

#sticky-nav {
  height: calc(80vh - 72px);
  table {
    .top_head {
      th {
        top: 0;
      }
    }
    .bottom_head {
      th {
        top: 38px;
      }
    }
  }
}

select {
  background: #ffffff;
  border: 1px solid #487aa4;
  background: url("~@/assets/img/fantasy/f_dropdwon_arrow.png") no-repeat right
    center;
  background-position: calc(100% - 10px) center;
}

@media (max-width: 599px) {
  #sticky-nav {
    table {
      .top_head {
        th {
          top: 0;
        }
      }

      .bottom_head {
        th {
          top: 31.69px;
        }
      }
    }
  }
}
</style>
